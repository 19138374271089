import React from 'react';
import './style.css';
import { FaPhoneAlt, FaCheck } from "react-icons/fa";
const AboutUsSection = () => {
  return (
    <>
      <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-7">
              <div
                className="section-title section-title-sm section-about position-relative pb-3 mb-4"
                style={{ maxWidth: "600px" }}
              >
                <h5
                  className="fw-bold text-primary text-uppercase"
                  style={{ color: "#06A3DA !important" }}
                >
                  ABOUT US
                </h5>
                <h1 className="mb-0">
                The Best IT Solution With 10 Years of Experience
                </h1>
              </div>
              <p className="mb-4" style={{ textAlign: 'justify' }}>Spondias, dedicated to personalized service, excels in Corporate Training & Project Consulting. Committed to tailored solutions, we leverage cutting-edge technologies for optimal outcomes. Our core portfolio includes Project Consulting, Skill Gap Analysis, Corporate Training & Recruitment Solutions, catering to diverse industries globally.</p>
              <div className="row g-0 mb-3">
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                  <h5 className="mb-3"><FaCheck className="fa fa-check text-primary me-3" />Award Winning</h5>
                  <h5 className="mb-3"><FaCheck className="fa fa-check text-primary me-3" />Professional Staff</h5>
                </div>
                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                  <h5 className="mb-3"><FaCheck className="fa fa-check text-primary me-3" />24/7 Support</h5>
                  <h5 className="mb-3"><FaCheck className="fa fa-check text-primary me-3" />Fair Prices</h5>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                  <FaPhoneAlt className="fa fa-phone-alt text-white" />
                </div>
                <div className="ps-4">
                  <h5 className="mb-2">Call to ask any question</h5>
                  <h4 className="text-primary mb-0">+91 7997711112</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-5" style={{ minHeight: '400px' }}>
              <div className="position-relative h-100">
                <img src="assets/about/about.jpg" alt="about" className="position-absolute w-100 h-100 rounded wow zoomIn" style={{ objectFit: 'cover' }} data-wow-delay="0.9s" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsSection;
